<template>
  <div class="container">
    <div class="header">
      <div class="title">{{campaign.campaign.title}}</div>
      <div
        class="main"
        :style="{
          backgroundImage:
            'url(' + campaign.post.images[0].image_info.thumbnail_url + ')',
        }"
      />
      <div class="squad">
        <div class="item">
          <img
            class="avatar"
            :src="campaign.post.author.avatar_url"
            alt="avatar"
          />
          <div class="info">
            <div class="info_top">{{ campaign.post.author.nickname }}</div>
            <div class="info_bottom">发起比赛</div>
          </div>
        </div>
        <div class="item">
          <div class="avatar-group">
            <template v-if="!!squads.length">
              <img
                class="avatar"
                :src="squad.avatar_url"
                alt="avatar"
                v-for="squad of squads"
                :key="squad.id"
              />
            </template>
            <img
              v-else
              class="default"
              alt="avatar"
              src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183ef1f-c27f-5da2-c477-080814185191?x-oss-process=style/origin"
            />
          </div>
          <div v-if="!!squads.length" class="info">
            <div class="info_top">{{ totalSquads }}支战队</div>
            <div class="info_bottom">已应战</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <div class="title">{{ campaign.campaign.title }}</div>
        <div class="item">
          <img class="" src="../../assets/images/clock.png" />
          <span>{{ generateCampaignDate }}</span>
        </div>
        <div class="item">
          <img class="" src="../../assets/images/poi-2.png" />
          <span>{{ campaign.post.poi.name }}</span>
        </div>
        <div class="item">
          <img class="" :src="campaign.post.author.avatar_url" />
          <span>{{ campaign.post.author.nickname }}</span>
          <img class="" src="../../assets/images/club-2.png" />
        </div>
      </div>
      <div class="right">
        <img class="" :src="qrcode" alt="" />
        <span>微信扫一扫</span>
      </div>
    </div>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
import { formatFeedsDate } from "@/utils/utils.js";
export default {
  name: "leagues-poster",
  data() {
    return {
      campaignId: "",
      campaign: {},
      qrcode: "",
      squads: [],
      totalSquads: 0
    };
  },
  computed: {
    /** 生成活动开始时间 */
    generateCampaignDate() {
      let {
        campaign: { start_time, end_time },
      } = this.campaign;
      start_time = +new Date(start_time);
      end_time = +new Date(end_time);
      return formatFeedsDate(start_time, end_time);
    },
  },
  async created() {
    let { campaignId } = this.$route.query;
    await this.getCampaign((this.campaignId = campaignId));
    await this.getJoinSquads(campaignId);
    this.getWechatCode(campaignId);
  },
  methods: {
    /** 获取活动详情 */
    getCampaign(campaignId) {
      $fetch
        .post("GetSharedCampaign", {
          postId: "",
          campaignId,
        })
        .then((campaign) => {
          this.campaign = campaign;
        });
    },
    /** 获取已参赛的战队列表 */
    getJoinSquads(campaignId) {
      $fetch
        .post("ListCompetitionSquads", {
          campaignId,
        })
        .then((res) => {
          this.totalSquads = res.squads.length;
          this.squads = res.squads.slice(0, 3);
        });
    },
    /** 获取活动的小程序码 */
    getWechatCode(campaignId) {
      const _ = this;
      $fetch
        .post("SharePost", {
          campaignId,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.mini_program_code_image;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1.146;

.container {
  @include column;
  position: relative;
  width: 100%;
  height: 152.9vw;
  box-sizing: content-box;
  border-radius: px2rem(24 * $multipleCount);
  overflow: hidden;

  > .header {
    @include column;
    width: 100%;
    height: 113.31vw;
    background: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183ef1f-6e01-3186-34bd-e6a3e6412b03?x-oss-process=style/origin)
      no-repeat center center / cover;
    overflow: hidden;

    $marginAside: px2rem(32 * $multipleCount);

    > .logo {
      width: px2rem(152 * $multipleCount);
      margin: px2rem(32 * $multipleCount) $marginAside 0;
    }

    > .title {
      position: relative;
      left: 0;
      width: calc(100% - px2rem(22 * $multipleCount * 2));
      margin: px2rem(48 * $multipleCount) px2rem(22 * $multipleCount) 0;
      font-size: px2rem(48 * $multipleCount);
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      line-height: px2rem(64 * $multipleCount);
      white-space: nowrap;
      overflow: hidden;
    }

    > .main {
      position: relative;
      width: calc(100% - px2rem(96 * $multipleCount));
      margin-left: px2rem(46 * $multipleCount);
      margin-right: px2rem(50 * $multipleCount);
      margin-top: px2rem(52 * $multipleCount);
      height: px2rem(420 * $multipleCount);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border: px2rem(4 * $multipleCount) solid #000;
      border-radius: px2rem(16 * $multipleCount);

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: px2rem(-46 * $multipleCount);
        transform: translateX(-50%);
        width: px2rem(406 * $multipleCount);
        height: px2rem(160 * $multipleCount);
        background: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0183ef23-354c-7988-d05b-0e0012a24061?x-oss-process=style/origin)
          no-repeat center center / contain;
      }
    }

    > .squad {
      $avatarWith: px2rem(80 * $multipleCount);

      position: relative;
      @include row(space-between, center);
      width: calc(100vw - px2rem(24 * $multipleCount * 2));
      height: $avatarWith;
      margin: px2rem(32 * $multipleCount) px2rem(24 * $multipleCount) 0;
      overflow: hidden;

      > .item {
        $marginAside: px2rem(8 * $multipleCount);
        @include row;
        flex: 1;
        height: 100%;
        overflow: hidden;

        > .avatar {
          width: $avatarWith;
          height: $avatarWith;
          border-radius: 50%;
          margin-right: $marginAside;
        }

        > .avatar-group {
          @include row;
          min-width: $avatarWith;
          height: $avatarWith;
          margin-left: $marginAside;

          > .default {
            width: px2rem(160 * $multipleCount);
            height: calc(100% - px2rem(2 * $multipleCount));
            object-fit: contain;
          }

          > .avatar {
            width: $avatarWith;
            height: $avatarWith;
            border-radius: 50%;

            &:not(:last-child) {
              margin-right: calc($avatarWith / -2);
            }
          }
        }

        > .info {
          @include column(space-between, flex-start);
          flex: 1;
          height: calc(100% - px2rem(8 * $multipleCount * 2));
          padding: px2rem(8 * $multipleCount) 0;
          white-space: nowrap;
          overflow: hidden;

          > [class^="info_"] {
            $textHeight: px2rem(24 * $multipleCount);

            @include ellipsis;
            width: 100%;
            height: $textHeight;
            font-size: $textHeight;
            line-height: $textHeight;
            font-family: "Alibaba PuHuiTi Bold", Alibaba PuHuiTi;
            font-weight: bold;
            color: #ffffff;
          }
        }

        &:nth-child(2) {
          flex-direction: row-reverse;

          > .avatar-group {
            margin-left: $marginAside;
            margin-right: 0;
          }

          > .info [class^="info_"] {
            text-align: right;
          }
        }
      }
    }
  }

  > .footer {
    @include row;
    width: calc(100% - px2rem(24 * 2 * $multipleCount));
    flex: 1;
    padding: px2rem(40 * $multipleCount) px2rem(24 * $multipleCount) 0;
    background-color: #fff;
    overflow: hidden;

    > .left {
      flex: 1;
      height: 100%;
      overflow: hidden;

      > .title {
        @include ellipsis;
        font-size: px2rem(32 * $multipleCount);
        height: px2rem(48 * $multipleCount);
        line-height: px2rem(48 * $multipleCount);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        font-weight: normal;
        color: #111111;
        white-space: nowrap;
        overflow: hidden;
      }
      > .item {
        @include row(flex-start, center);
        margin-top: px2rem(16 * $multipleCount);

        > img {
          &:first-of-type {
            width: px2rem(32 * $multipleCount);
            height: px2rem(32 * $multipleCount);
            border-radius: 50%;
          }

          &:nth-of-type(2) {
            width: px2rem(42 * $multipleCount) !important;
            width: px2rem(24 * $multipleCount);
            margin-left: px2rem(8 * $multipleCount);
          }
        }

        > span {
          @include ellipsis;
          font-size: px2rem(24 * $multipleCount);
          line-height: px2rem(32 * $multipleCount);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a3a3a3;
          margin-left: px2rem(8 * $multipleCount);
        }
      }
    }

    > .right {
      @include column(flex-start, center);
      flex-shrink: 0;
      width: px2rem(142 * $multipleCount);
      height: 100%;
      margin-left: px2rem(16 * $multipleCount);

      > img {
        width: px2rem(142 * $multipleCount);
        height: px2rem(142 * $multipleCount);
        margin-top: px2rem(2 * $multipleCount);
      }

      > span {
        font-size: px2rem(24 * $multipleCount);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9f9f9f;
        line-height: px2rem(32 * $multipleCount);
        margin-top: px2rem(16 * $multipleCount);
      }
    }
  }
}
</style>
