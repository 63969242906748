export const getMonthDay = (timestamp) => {
  timestamp *= 1e3;
  const date = new Date(timestamp);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const _month = month < 10 ? `0${month}` : month;
  const _day = day < 10 ? `0${day}` : day;
  return `${_month}-${_day}`;
};

/** 格式化 feeds 日期 */
export function formatFeedsDate(startTime, endTime) {
  /** 容错处理 */
  if (!startTime || !endTime) {
    return `待定`;
  }
  /** 开始日期计算 */
  const date = new Date(startTime);
  const month = date.getMonth() + 1,
    day = date.getDate(),
    week = date.getDay(),
    hour = date.getHours(),
    minutes = date.getMinutes();
  const weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  const monthStr = month < 10 ? "0" + month : month;
  const dayStr = day < 10 ? "0" + day : day;
  const hourStr = hour < 10 ? "0" + hour : hour;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  /** 结束日期计算 */
  const endDate = new Date(endTime);
  const endHour = endDate.getHours(),
    endMinutes = endDate.getMinutes();
  const endHourStr = endHour < 10 ? "0" + endHour : endHour,
    endMinutesStr = endMinutes < 10 ? "0" + endMinutes : endMinutes;
  return `${weeks[week]} ${monthStr}.${dayStr} ${hourStr}:${minutesStr}-${endHourStr}:${endMinutesStr}`;
}

/** 获取完整日期 */ 
export function getDate(time) {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  const seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return [year, month, day, hours, minutes, seconds];
}

export function downloadImg(dataUrl) {
  var link = document.createElement('a');
  link.download = 'image.png';
  link.href = dataUrl;
  link.click();
}

/** 字符串哈希到指定区间 */ 
export function hashString(str, max) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return (hash % max + max) % max;
}
